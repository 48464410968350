<template>
  <div class="container" element-loading-text="Loading" v-loading="listLoading">
    <BodyContent :pageData="pageData" @initData="initData">
      <div slot="table">
        <div class="table-body-content" style="padding: 20px 20px 10px 20px">
          <div class="table-header">
            <div class="search-box">
              <div class="search-title">指标性质</div>
              <el-select
                v-model="requestParams.state"
                style="width: 250px; margin-right: 20px"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search-box">
              <div class="search-title">责任部门</div>
              <el-select
                v-model="requestParams.state"
                style="width: 250px; margin-right: 20px"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search-box">
              <div class="search-title">评分类型</div>
              <el-select
                v-model="requestParams.state"
                style="width: 250px; margin-right: 20px"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="search-box">
              <div class="search-title">关键字</div>
              <el-input
                placeholder="请输入指标编号、指标名称进行查询"
                clearable
                style="width: 250px; margin-right: 20px"
                v-model.trim="requestParams.keyword"
                maxlength="100"
              />
            </div>
            <el-button type="text">重置</el-button>
          </div>


          <div class="table-content">
            <el-button type="text" icon="el-icon-plus" @click="dialogFormVisible = true">新增指标</el-button>
            <el-button type="text" icon="el-icon-delete">删除指标</el-button>
            <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
              <el-table-column align="center" type="selection" width="80"></el-table-column>
              <el-table-column align="center" label="指标编号">
                <template slot-scope="scope">TAR0001</template>
              </el-table-column>
              <el-table-column align="center" label="指标名称">
                <template slot-scope="scope"><span style="color:#5D7CE1" @click="dialogFormVisible = true;edit=false">用户服务满意率</span></template>
              </el-table-column>
              <el-table-column align="center" label="指标分类">
                <template slot-scope="scope">能力考核</template>
              </el-table-column>
              <el-table-column align="center" label="指标性质">
                <template slot-scope="scope">定量指标</template>
              </el-table-column>
              <el-table-column align="center" label="指标描述">
                <template slot-scope="scope">每月25号由售后服务部填写完服务满意度调...</template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-edit-outline" @click="dialogFormVisible = true"></el-button>
                    <el-button type="text" icon="el-icon-delete" @click="showRemoveHandler(scope.row.id)"></el-button>
                  </template>
                </el-table-column>
            </el-table>
          </div>
        </div>
        <el-dialog  :visible.sync="dialogFormVisible" width="70%">
          <div slot="title" class="dialog_title">
            {{ruleForm.id ? '编辑' : '新增'}}
            <el-button icon="el-icon-rank" type="text" ></el-button>
          </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="指标编号：" prop="name" style="width:50%;display:inline-block">
                  <el-input v-if="edit" v-model="ruleForm.name" maxlength="200"></el-input>
                  <span v-else>TAR0001</span>
                </el-form-item>
                <el-form-item label="指标名称：" prop="name" style="width:50%;display:inline-block">
                  <el-input v-if="edit" v-model="ruleForm.name" maxlength="200"></el-input>
                  <span v-else>用户服务满意率</span>
                </el-form-item>
                <el-form-item label="进位规则：" style="width:50%;display:inline-block" >
                  <el-select v-if="edit" style="width:100%" v-model="ruleForm.name" placeholder="请选择">
                  </el-select>
                  <span v-else>能力考核</span>
                </el-form-item>
                <el-form-item label="指标性质：" style="width:50%;display:inline-block" >
                  <el-radio-group v-if="edit" v-model="ruleForm.name">
                    <el-radio :label="3">定量指标</el-radio>
                    <el-radio :label="6">定性指标</el-radio>
                  </el-radio-group>
                  <span v-else>定量指标</span>
                </el-form-item>

                <el-form-item label="责任部门：" style="width:50%;display:inline-block" >
                  <el-select v-if="edit" style="width:100%" v-model="ruleForm.name" placeholder="请选择">
                  </el-select>
                  <span v-else>售后服务部</span>
                  
                </el-form-item>
                <el-form-item label="评分类型：" style="width:50%;display:inline-block" >
                  <el-radio-group v-if="edit" v-model="ruleForm.name">
                    <el-radio :label="3">选项输入</el-radio>
                    <el-radio :label="6">数字输入</el-radio>
                  </el-radio-group>
                  <span v-else>选项输入</span>
                </el-form-item>

                <el-form-item label="评分选项：">
                  <el-button type="text" icon="el-icon-plus" v-if="edit">新增选项</el-button>
                  <el-button type="text" icon="el-icon-delete" v-if="edit">删除选项</el-button>
                  
                  <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
                    <el-table-column align="center" type="selection" width="80" v-if="edit"></el-table-column>
                    <el-table-column align="center" type="index" label="序号" width="80" v-else></el-table-column>
                    <el-table-column align="center" label="指标分值">
                      <template slot-scope="scope">
                        <el-input-number v-if="edit" controls-position="right"></el-input-number>
                        <span v-else>90-100</span>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="评分规则">
                      <template slot-scope="scope">
                        <el-input v-if="edit"></el-input>
                        <span v-else>非常满意</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>

                <el-form-item label="指标描述：" prop="name">
                  <el-input v-if="edit" v-model="ruleForm.name" type="textarea" :rows="2" placeholder="请输入指标说明" maxlength="1000"></el-input>
                  <div v-else>每月25号由售后服务部填写完服务满意度调查表，报销售支持部调查反馈用户服务一次成功率。</div>
                  <el-switch></el-switch>高级设置
                </el-form-item>


                <el-form-item label="量化单位：" style="width:50%;display:inline-block" >
                  <el-select v-if="edit" style="width:100%" v-model="ruleForm.name" placeholder="请选择">
                  </el-select>
                  <span v-else>支持单位扩展</span>
                </el-form-item>
                <el-form-item label="评分产生方式：" style="width:50%;display:inline-block" >
                  <el-radio-group v-if="edit"  v-model="ruleForm.name">
                    <el-radio :label="3">直接输入分数</el-radio>
                  </el-radio-group>
                  <span v-else>15</span>
                </el-form-item>
                <el-form-item label="评分标准：" prop="name">
                  <el-input v-if="edit" v-model="ruleForm.name" type="textarea" :rows="2" placeholder="请输入评分标准" maxlength="1000"></el-input>
                  <span v-else>满意率大于95%</span>
                </el-form-item>
          </el-form>


          
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </BodyContent>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      edit:true,
      activeNames:'1',
      detailed:false,
      assessment:false,
      activeName:'first',
      innerVisible:false,
      dialogFormVisible:false,
      listLoading: false,
      stateList: [],
      requestParams: {
        time:'',
        state: "",
      },
      rules:{
        name:[{ required: true, message: '请填写名称' }],
        state:[{ required: true, message: '请选择状态' }],
        obj:[{ type: 'array',required: true, message: '请选择考核对象',trigger: 'change'  }],
      },
      ruleForm:{
        name:'',
        id:'',
        state:false,
        obj:[{},{}]
      },
      options: [],
      pageData: {},
      tableData:[{},{},{}]
    };
  },
  methods: {
    initData() {},
  },
};
</script>
<style lang="scss" scoped>
.title{
    margin-bottom: 5px;
}
.left{

  border: 1px solid #DCDFE6;
  border-radius: 4px;
  /deep/.el-input__inner{
    border: 0;
    border-bottom: 1px solid #DCDFE6;
  }
  .list{
    padding: 0 5px;
  div{
    padding: 10px;
  }
}
}

</style>
