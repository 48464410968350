<template>
    <div class="container">
        <el-container>
            <el-aside width="350px">
                <div class="left-title">指标分类</div>
                <div class="left-btns">
                    <el-button type="text" @click="dialogFormVisible = true" icon="el-icon-plus"></el-button>
                    <el-button type="text" icon="el-icon-delete"></el-button>
                    <el-button type="text" icon="el-icon-arrow-down"></el-button>
                    <el-button type="text" icon="el-icon-arrow-up"></el-button>
                    <el-button type="text" icon="el-icon-back"></el-button>
                    <el-button type="text" icon="el-icon-right"></el-button>
                    <el-button type="text" icon="el-icon-bottom"></el-button>
                </div>
                <div class="left-all">
                    全部指标（15）
                </div>

                  <el-input
                    style="margin:10px;width:330px"
                    placeholder="请输入分类名称进行查询"
                    suffix-icon="el-input__icon el-icon-search"
                    v-model="input1">
                </el-input>
                <div class="left-navigation">
                    <div class="active">
                        业绩考核（5）  
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                <el-button class="btn" type="text" icon="el-icon-more"></el-button>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>编辑分类</el-dropdown-item>
                                <el-dropdown-item>删除分类</el-dropdown-item>
                                <el-dropdown-item>新增同级分类</el-dropdown-item>
                                <el-dropdown-item>新增子分类</el-dropdown-item>
                                <el-popover
                                    placement="right"
                                    style="display:block"
                                    class="popover">
                                    <div>
                                        <div><el-button type="text" style="color:#000">下移</el-button></div>
                                        <div><el-button type="text" style="color:#000">升降级</el-button></div>
                                    </div>
                                    <el-button slot="reference" type="text" icon="el-icon-more">移动分类</el-button>
                                </el-popover>
                            </el-dropdown-menu>
                        </el-dropdown>
                        
                    </div>
                    <div>能力考核（1） <el-button class="btn" type="text" icon="el-icon-more"></el-button></div>
                </div>
            </el-aside>
            <el-main>
                <indexCountent></indexCountent>
            </el-main>
        </el-container>


        <el-dialog :visible.sync="dialogFormVisible">
            <div slot="title" class="dialog_title">
                新增分类
                <el-button icon="el-icon-rank" type="text" ></el-button>
              </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="上级分类：" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="分类名称：" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="分类说明：" prop="name">
                    <el-input  type="textarea" :rows="2" v-model="ruleForm.name"></el-input>
                </el-form-item>
                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div>
            </el-dialog>

    </div>
</template>
<script>
import indexCountent from "./components/indexContent.vue"
export default {
    components:{indexCountent},
    data(){
        return{
            input1:'',
            dialogFormVisible:false,
            ruleForm:{
                name:'',
            },
            rules:{}
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        .el-aside{
            background: rgb(250,250,250);
            .left-title{
                padding: 10px;
                background: rgb(238,245,254);
            }
            .left-btns{
                text-align: center;
            }
            .left-all{
                margin: 10px;
                border: 1px solid rgb(93,124,225);
                color: rgb(93,124,225);
                padding: 10px;
                border-radius: 10px;
                text-align: center;
            }

            .left-navigation{
                &>div{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 10px 0 40px;
                    &.active{
                        background: rgb(93,124,225);
                        color: #fff;
                        .btn{
                            color: #fff;
                        }
                    }
                    .popover{
                        display: block;
                    }
                }
               
            }
        }
        
    }
</style>